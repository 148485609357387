/*
 :::: Color Varient ::::
*/
const palette = {
  white: '#ffffff',
  black: '#000000',
  /* gray */
  gray0: '#fafafa',
  gray1: '#e7e7e7',
  gray2: '#c3c3c3',
  gray3: '#9e9e9e',
  gray4: '#868686',
  gray5: '#656565',
  gray6: '#505050',
  gray7: '#3c3c3c',
  gray8: '#282828',
  /* red */
  red0: '#ffe8e8',
  red1: '#ffd0d1',
  red2: '#ff8a8b',
  red3: '#ff4445',
  red4: '#ff1517',
  red5: '#bf1011',
  red6: '#990d0e',
  red7: '#73090a',
  red8: '#4d0607',
  /* orange */
  orange0: '#fff0ec',
  orange1: '#ffe2d9',
  orange2: '#ffb6a0',
  orange3: '#ff8966',
  orange4: '#ff6c40',
  orange5: '#bf5130',
  orange6: '#994126',
  orange7: '#73311d',
  orange8: '#4d2013',
  /* yellow */
  yellow0: '#fffce6',
  yellow1: '#fffacc',
  yellow2: '#fff280',
  yellow3: '#feea33',
  yellow4: '#fee500',
  yellow5: '#bfac00',
  yellow6: '#988900',
  yellow7: '#726700',
  yellow8: '#4c4500',
  /* green */
  green0: '#e6f7e6',
  green1: '#cdefcc',
  green2: '#83d880',
  green3: '#39c133',
  green4: '#07b100',
  green5: '#058500',
  green6: '#046a00',
  green7: '#035000',
  green8: '#023500',
  /* blue */
  blue0: '#ebf1ff',
  blue1: '#d7e3ff ',
  blue2: '#9cb9ff ',
  blue3: '#618eff ',
  blue4: '#3972ff ',
  blue5: '#2b56bf ',
  blue6: '#224499 ',
  blue7: '#1a3373 ',
  blue8: '#11224d ',
  /* purple */
  purple0: '#f2ebff',
  purple1: '#d7e3ff',
  purple2: '#be9cff',
  purple3: '#9761ff',
  purple4: '#7d39ff',
  purple5: '#5e2bbf',
  purple6: '#4b2299',
  purple7: '#381a73',
  purple8: '#26114d',
};

export default palette